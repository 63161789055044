<template>
	<div class="articlesTable">
		<v-card class="glassmorphism" :color="$vuetify.theme.dark ? 'transparent' : 'white'">
			<v-card-title>
				Tous les articles
			</v-card-title>
			<v-card-text>
				<v-data-table style="background-color: transparent;" :headers="headers" :items="articles"
					:search="search" single-expand show-expand>
					<template v-slot:[`item.img`]="{ item }">
						<v-row align="center" justify="center">
							<v-img v-bind:src="$functions.getImgLink(item.img.main, 50)" contain
								:lazy-src="$functions.getImgLink(item.img.main, 50)"
								aspect-ratio="1" max-width="200" max-height="200">
								<template v-slot:placeholder>
									<v-row class="fill-height ma-0" align="center" justify="center">
										<v-progress-circular indeterminate :size="50" :width="1" color="primary">
										</v-progress-circular>
									</v-row>
								</template>
							</v-img>
						</v-row>
					</template>

					<template v-slot:[`item.introduction`]="{ item }" v-if="$vuetify.breakpoint.mdAndUp">
						<span v-html="item.shortDesc"></span>
					</template>

					<template v-slot:[`item.author`]="{ item }">
						{{ item.author.firstName }} {{ item.author.lastName }}
					</template>

					<template v-slot:[`item.date`]="{ item }">
						{{ $functions.getDateFormat(item.creationDate) }}
					</template>

					<template v-slot:[`item.actions`]="{ item }">
						<v-btn text icon :to="`/blog/${blogInfo.id}/article/${item.id}`">
							<v-icon color="primary">mdi-eye</v-icon>
						</v-btn>
						<v-btn text icon :to="`/blog/${blogInfo.id}/add/${item.id}`">
							<v-icon color="primary">mdi-pencil</v-icon>
						</v-btn>
						<v-btn text icon>
							<v-icon color="error" @click="articleToDelete = item, dialog.delete = true">mdi-delete
							</v-icon>
						</v-btn>
					</template>

					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length" class="px-0">
							<v-row class="sp-details">
								<v-col cols="12" v-if="item.img.secondary">
									<v-img id="blurMe" height="400px" width="81vw"
										v-bind:src="$functions.getImgLink(item.img.secondary)" style="margin-bottom: -150px;"></v-img>
								</v-col>

								<v-col cols="6">
									<v-card-title>{{ item.title }}</v-card-title>
									<v-card-subtitle>{{ item.subTitle }}</v-card-subtitle>
								</v-col>
							</v-row>

							<v-divider></v-divider>

							<v-container>
								<span v-html="item.shortDesc"></span>
							</v-container>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<v-dialog v-model="dialog.delete" persistent max-width="50%">
			<v-card>
				<v-card-title class="headline">
					Voulez-vous supprimer cet article ?
				</v-card-title>
				<v-card-text>
					<p>Après avoir cliqué sur "Confirmer la suppression", l'article "{{ articleToDelete.title }}" sera
						définitivement supprimé.</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="removeArticle()">
						Confirmer la suppression
					</v-btn>
					<v-btn color="primary" text @click="dialog.delete = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style>
	#blurMe {
		filter: blur(1px);
		-webkit-mask-image: -webkit-gradient(linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 1)),
				to(rgba(0, 0, 0, 0)));
	}

	#lateral {
		position: fixed;
		top: 15px;
		right: 15px;
		margin: 0 0 16px 16px;
	}

	#create .v-speed-dial {
		position: absolute;
	}

	#create .v-btn--floating {
		position: relative;
	}

</style>

<script>
	export default {
		props: {
			blogInfo: {
				type: Object
			},
			userInfo: {
				type: Object
			}
		},
		data() {
			return {
				articles: [],

				headers: [{
						text: 'Image',
						value: 'img',
						sortable: false,
						width: 50,
					},
					{
						text: 'Titre',
						value: 'title'
					},
					{
						text: 'Auteur',
						value: 'author'
					},
					{
						text: 'Date',
						value: 'date',
						width: 100,
					},
					{
						text: 'Introduction',
						value: 'introduction'
					},
					{
						align: "end",
						text: "",
						value: "actions",
						width: 180,
						sortable: false
					},
				],

				expanded: [],
				search: "",

				dialog: {
					delete: false
				},

				articleToDelete: this.$models.blogArticle,
			}
		},
		methods: {
			removeArticle() {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.blogInfo.id).collection("articles").doc(this.articleToDelete.id), "L'article a bien été supprimé.");
				this.dialog.delete = false;
			}
		},
		mounted() {
			this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.blogInfo.id)
				.collection("articles").onSnapshot(ref => {
					this.articles = [];

					ref.forEach(document => {
						this.$db.collection("users").doc(document.data().author).get().then(doc => {
							this.articles.push({
								...document.data(),
								author: {
									...doc.data(),
									id: doc.id
								},
								id: document.id
							});
						});
					});
				});
		}
	}

</script>
