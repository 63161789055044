<template>
	<div class="blog">

		<Head :title="blogInfo.name" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container class="mt-5 maxWidth">

			<v-row>

				<v-col cols="12" class="px-6">
					<v-btn class="float-right neon" color="white" :block="$vuetify.breakpoint.xsOnly" outlined
						:to="`/blog/${id}/add`">
						<v-icon left>
							mdi-newspaper-plus
						</v-icon>
						Ajouter un article
					</v-btn>
				</v-col>

				<v-col cols="12">
					<ArticlesTable v-if='blogInfo.id && userInfo.id' :blogInfo="blogInfo" :userInfo="userInfo" />
				</v-col>

				<v-col cols="12">
					<v-card max-width="400" class="mx-auto">
						<v-card-title>
							<v-row>
								<v-col cols="12">
									<Title
										titleWeight="regular"
										class="ma-0"
									>
									Gestion du blog
									</Title>
									<v-btn class="neon ml-2" icon @click="dialog.edit = true">
										<v-icon>mdi-cog</v-icon>
									</v-btn>
								</v-col>
								<v-col cols="12" class="ma-0 pa-0">
									<v-btn block class="neon my-2" :color="$vuetify.theme.dark ? 'white' : 'primary'"
										outlined v-for="item in integrations" :key="item.id" @click="goTo(item.link)">
										Voir le blog sur {{ item.name }}
									</v-btn>
								</v-col>
							</v-row>
						</v-card-title>
					</v-card>
				</v-col>

			</v-row>
		</v-container>

		<v-dialog v-model="dialog.delete" width="600">

			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger :title="dialog.blog ? 'Supprimer le Blog' : 'Supprimer les Articles'" small whiteText
					:noButton="$vuetify.breakpoint.smAndUp" icon content="" @click.native="dialog.delete = false" />
				<v-col cols="12">
					<v-card class="bt-error" outlined>
						<v-card-title class="headline">
							Voulez-vous supprimer <span class="mx-1" v-if="dialog.blog == false">les articles de</span>
							{{ blogInfo.name }} ?
						</v-card-title>
						<v-card-text>
							<p>Après avoir cliqué sur "Confirmer la suppression", 
								<span v-if="dialog.blog == false">les articles ne serons plus disponible, mais le blog seras toujours disponible.</span>
								<span v-if="dialog.blog == true">le blog ne seras plus disponible et tous ses articles seront supprimés.</span>
							</p>

							<h3 v-if="articles.length">Les articles suivants seront affectés</h3>
							<h3 v-if="!articles.length">Le blog ne comporte aucun articles</h3>

							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form>
											<v-row>
												<v-col cols="12" md="12" lg="6" v-for="item in articles" :key="item.id">

													<v-card class="zoom" hover outlined
														@click="goTo(`/blog/${id}/article/${item.id}`)">

														<v-img v-if="item.img.main"
															gradient="to top left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
															class="pa-4" height="150" :src="$functions.getImgLink(item.img.main, 200)">
															<h3>{{ item.title }}</h3>
															<h4 class="absolute top left pl-4 pt-10 subtitle-1">
																{{ item.subTitle }}
															</h4>

															<v-btn small outlined color="white"
																class="neon absolute bottom right mb-4 mr-4"
																@click="goTo(`/blog/${id}/article/${item.id}`)">
																Voir l'article
															</v-btn>
														</v-img>

														<v-card hover outlined height="150" v-if="!item.img.main"
															class="d-flex justify-center">
															<h3 class="absolute top left pl-4 pt-4">{{ item.title }}
															</h3>
															<h4 class="absolute top left pl-4 pt-10 subtitle-1">
																{{ item.subTitle }}
															</h4>
															<v-icon>mdi-newspaper</v-icon>
															<v-btn small outlined
																:color="$vuetify.theme.dark ? 'white' : 'black'"
																class="neon absolute bottom right mb-4 mr-4"
																@click="goTo(`/blog/${id}/article/${item.id}`)">
																Voir l'article
															</v-btn>
														</v-card>
													</v-card>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-row>
								<v-col cols="12">
									<v-form>
										<v-row>
											<v-col cols="12" v-if="dialog.blog == true">
												<v-spacer></v-spacer>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error"
													class="neon evidence mb-4" @click="removeBlog(blogInfo.id)">
													Confirmer la suppression du blog
												</v-btn>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
													class="neon evidence mb-4 float-right"
													@click="dialog.delete = false">
													Annuler
												</v-btn>
											</v-col>
											<v-col cols="12" v-if="dialog.blog == false">
												<v-spacer></v-spacer>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error"
													class="neon evidence mb-4" @click="removeAllArticles()">
													Confirmer la suppression des articles
												</v-btn>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
													class="neon evidence mb-4 float-right"
													@click="dialog.delete2 = false">
													Annuler
												</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-card>

		</v-dialog>

		<v-dialog v-model="dialog.edit" max-width="960">
			<h1 class="font-weight-regular ma-4 white--text">{{ blogInfo.name }}</h1>
			<v-col cols="12">
				<v-card>
					<v-card-title>
						<h2 class="font-weight-regular">Informations</h2>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="8" md="10">
								<v-text-field label="Modifier le nom" :rules="$fieldsRules.required('nom')"
									v-model="blogInfo.name" @keyup.enter="modifyBlog()">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="4" md="2">
								<v-btn class="mt-4 neon" block color="primary" outlined @click="modifyBlog()">Modifier
								</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<span class="font-italic font-weight-light">
									Nombres d'articles : {{ articles.length }}
								</span>
							</v-col>
							<v-col cols="12">
								<span class="font-italic font-weight-light">Identifiant
									du Blog : {{ blogInfo.id }}</span>
							</v-col>
							<v-col cols="12">
								<span class="font-italic font-weight-light">Date de
									Création :
									{{ $functions.getDateFormat(blogInfo.creationDate) }}</span>
							</v-col>
							<v-col cols="12">
								<span class="font-italic font-weight-light" v-if="blogInfo.modificationDate">Dernière
									Modification :
									{{ $functions.getDateFormat(blogInfo.modificationDate) }}</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-card>
					<v-card-title>
						<h2 class="font-weight-regular">Intégrations</h2>
					</v-card-title>
					<v-card>
						<v-card-title class="headline">
							Ajouter une intégration
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row class="mb-8">
									<v-col cols="12">
										<v-text-field label="Nom" :rules="$fieldsRules.required('nom')"
											v-model="integration.name" @keyup.enter="addIntegration()"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field label="Lien" :rules="$fieldsRules.required('lien')"
											v-model="integration.link" @keyup.enter="addIntegration()"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-btn class="float-right neon" width="200" color="success" outlined
											:block="$vuetify.breakpoint.xsOnly" @click="addIntegration()">
											Ajouter
										</v-btn>
									</v-col>
								</v-row>

								<v-col cols="12" v-for="item in integrations" :key="item.id">
									<v-row>
										<v-col cols="10">
											<v-btn class="neon" block outlined @click="$functions.goTo(item.link)">
												{{ item.name }} </v-btn>
										</v-col>
										<v-col cols="2">
											<v-btn class="neon" block outlined color="error"
												@click="removeIntegration(item.id)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</v-container>
						</v-card-text>
					</v-card>
				</v-card>

			</v-col>
			<v-col cols="12">
				<v-card>
					<v-card-actions>
						<v-btn color="grey" text @click="dialogs.modify = false">
							Annuler
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn class="neon float-right" width="200" color="success" outlined
							@click="dialog.edit = false">Valider
						</v-btn>
					</v-card-actions>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn class="neon" color="warning" outlined @click="dialog.delete = true, dialog.blog = false">
							Supprimer
							tous les articles</v-btn>
						<v-btn class="neon" color="error" outlined @click="dialog.delete = true, dialog.blog = true"
							:to="'/Blogs'">
							Supprimer Le Blog
						</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-dialog>

	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	// import ArticleNavigation from '@/components/modules/@blog/Navigation';
	import ArticlesTable from '@/components/modules/@blog/ArticlesTable';
	import Title from '@/components/graphics/title';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'


	export default {
		head: {
			title: {
				inner: "Blog"
			}
		},
		name: 'Blog',
		props: {
			'id': {
				type: String
			}
		},
		components: {
			Head,
			ArticlesTable,
			Title,
			ButtonTrigger,
		},
		data() {
			return {
				userInfo: this.$models.user,

				blogInfo: this.$models.blog,

				articles: [],
				integrations: [],

				integration: {},

				dialog: {
					delete: false,
					delete2: false,
					integrations: false,
					edit: false,
					blog: false,
				},

				Head: {

					Title: 'Blogs',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "15vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
			};
		},
		methods: {
			modifyBlog() {
				if (!this.blogInfo.name) return;

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).set({
					name: this.blogInfo.name,
					modificationDate: new Date()
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "Le blog a bien été modifié.",
						color: "success"
					});
				});
			},
			removeBlog(blogID) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(blogID), "Le blog a bien été supprimé.");
				this.dialog.delete = false;

				this.$router.push({
					name: 'Blogs'
				})
			},
			removeAllArticles() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection("articles").get().then(() => {
					this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection("articles").get().then(ref => {
						ref.forEach(document => {
							this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection("articles").doc(document.id));
						});
					});

					this.dialog.delete2 = false;

					this.$store.dispatch('setSnackbar', {
						text: "Les articles ont bien été supprimés.",
						color: "success"
					});
				});
			},
			addIntegration() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection(
					"integrations").add(this.integration).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "L'intégration à été ajoutée.",
						color: "success"
					});

					this.integration = {};
				})
			},
			removeIntegration(idIntegration) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection("integrations").doc(idIntegration), "L'intégration à été supprimée.");
			},
			goTo(link) {
				window.open(link, "_blank");
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.onSnapshot(doc => {
						this.blogInfo = {
							...doc.data(),
							id: doc.id
						}
					});

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.collection("articles").onSnapshot(ref => {
						this.articles = [];

						ref.forEach(document => {
							this.articles.push({
								...document.data(),
								id: document.id
							});
						});
					});

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.collection("integrations").onSnapshot(ref => {
						this.integrations = [];

						ref.forEach(document => {
							this.integrations.push({
								...document.data(),
								id: document.id
							});
						});
					})
			});
		}
	}

</script>
